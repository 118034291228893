import { initializeEventEmitter } from "./eventEmitter";
const GlobalsKey = "__EDUADMIN_GLOBALS";

//Mindre script som kunder kommer att kunna länka in på sina egna sidor för att kunna lägga till huvudscriptet till de scriptbaserade anmälningsformulären
(function () {
	function resolveHost(url: string) {
		//todo använd new URL istället när vi släpper IE supporten
		const tmpLnk = document.createElement("a");
		tmpLnk.href = url;
		const hostName = tmpLnk.hostname;
		const host = `https://${hostName}/`;
		if (hostName.indexOf("localhost") > -1) {
			return host + "/legaonlineFaceCore/";
		} else if (hostName.indexOf("lodev.se") > -1) {
			return host + "/legaonlineFace/";
		} else {
			return host;
		}
	}

	const eduAdminCustomerScript = document.getElementById(
		"eduadmin-customer-script"
	);
	if (!eduAdminCustomerScript) {
		return;
	}

	const loaderHtml = `<div class="eduadmin-loader"></div>`;
	const courseDivs = document.querySelectorAll("[data-eduadmin-courses]");
	const eventDivs = document.querySelectorAll("[data-eduadmin-events]");

	function replaceInnerHtmlWithLoader(elements: NodeListOf<Element>) {
		for (let i = 0; i < elements.length; i++) {
			elements[i].innerHTML = loaderHtml;
		}
	}

	replaceInnerHtmlWithLoader(courseDivs);
	replaceInnerHtmlWithLoader(eventDivs);

	const customerScriptSrc = eduAdminCustomerScript.getAttribute(
		"src"
	) as string;
	const rootUrl = resolveHost(customerScriptSrc);

	const portalKey = eduAdminCustomerScript.getAttribute(
		"data-eduadmin-script"
	);

	if (!window[GlobalsKey]) {
		window[GlobalsKey] = {};
	}
	window[GlobalsKey]["rootUrl"] = rootUrl;
	initializeEventEmitter(window[GlobalsKey]);

	let url = `${rootUrl}webportal/${portalKey}/javascript/currentjavascript`;

	let httpReq;

	//Kontrollerar om webbläsaren är ie10 eller äldre ie version, i så fall används XDomainRequest, vid andra webbläsare används XMLHttpRequest
	if (navigator.userAgent.toLowerCase().indexOf("msie") !== -1) {
		httpReq = new (window as any).XDomainRequest();
	} else {
		httpReq = new XMLHttpRequest();
	}

	httpReq.open("GET", url);
	httpReq.onload = function () {
		const json = httpReq.responseText;
		const jsonObj = JSON.parse(json);

		const jsUrls: string[] = jsonObj.js;
		const cssUrls: string[] = jsonObj.css;
		const publicWebpackPath = jsonObj.publicWebpackPath;

		if (publicWebpackPath) {
			window[GlobalsKey].publicWebpackPath = publicWebpackPath;
		}

		const scripts = document.createDocumentFragment();
		const css = document.createDocumentFragment();

		for (let i = 0; i < cssUrls.length; i++) {
			const linkTag = document.createElement("link");
			linkTag.setAttribute("id", "eduadmin-course-css_" + i);
			linkTag.setAttribute("href", cssUrls[i]);
			linkTag.setAttribute("type", "text/css");
			linkTag.setAttribute("rel", "stylesheet");
			css.appendChild(linkTag);
		}

		for (let i = 0; i < jsUrls.length; i++) {
			const scriptTag = document.createElement("script");
			scriptTag.setAttribute("id", "eduadmin-course-script_" + i);
			scriptTag.setAttribute("type", "text/javascript");
			scriptTag.setAttribute("src", jsUrls[i]);
			scripts.appendChild(scriptTag);
		}

		document.head.insertBefore(css, document.head.childNodes[0]);
		document.head.appendChild(scripts);
	};

	httpReq.send(null);
})();
