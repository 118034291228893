export function initializeEventEmitter(globalsObject: any) {
	if (globalsObject.events) {
		return;
	}

	const subscribers: Record<string, Function[]> = {};
	const getEventSubscribers = (eventName: string): Function[] => {
		return subscribers[eventName] || (subscribers[eventName] = []);
	};

	globalsObject.events = {
		subscribe(event: string, handler: Function) {
			getEventSubscribers(event).push(handler);
			return () => {
				const eventSubs = getEventSubscribers(event);
				const ioSubscriber = eventSubs.indexOf(handler);
				if (ioSubscriber >= 0) {
					eventSubs.splice(ioSubscriber, 1);
				}
			};
		},
		emit(event: string, ...args: any[]) {
			const eventSubs = getEventSubscribers(event);
			for (let i = 0; i < eventSubs.length; i++) {
				eventSubs[i](...args);
			}
		},
	};
}
